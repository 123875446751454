import posthog from 'posthog-js'

const channel_name = 'Partners Portal'
export const sendAnalytics = (eventName: string, properties: Record<string, any> = {}) => {
  posthog.capture(eventName, { channel: channel_name, ...properties })
}

export const setPosthogIdentity = (data: Record<string, any>) => {
  posthog.identify(
    data?.email,
    { email: data?.email, name: `${data?.fname} ${data?.lname}`, channel: channel_name }
  )
}

export const resetPosthogIdentity = () => {
  posthog.reset()
}
