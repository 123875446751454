
import {defineComponent} from "vue";
export default defineComponent({
  name: "ShFullModalComponent.vue",
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          type: 'info',
          title: 'Alert',
          description: '',
          buttonText: 'Okay'
        };
      }
    }
  },
  methods: {
    actionClicked () {
      this.$emit('action-clicked');
    }
  }
})
