import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full pt-2 bg-white border-t border-t-grays-black-700" }
const _hoisted_2 = { class: "flex justify-between items-end" }
const _hoisted_3 = { class: "pb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "tabs")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "action")
      ])
    ]),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "breadcrumbs")
    ])
  ]))
}