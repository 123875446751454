import {StoreOptions} from "vuex";
import {axiosInstance} from "@/plugins/axios";
import { sendAnalytics } from "@/composables/analytics";

interface VehicleState {
  addVehicleForm: any; // Holds api response temporarily while add vehicle process is in progress
  loadingVehicle: boolean;
  vehicleData: any;
}

export default <StoreOptions<VehicleState>>{
  namespaced: true,
  actions: {
    setVehicleFormData ({commit}, data: any) {
      commit('setVehicleFormData', data);
    },
    setVehicleData ({commit}, data: any) {
      commit('setVehicleData', data);
    },
    async fetchVehicleInfo ({commit}, vehicleId: number) {
      try {
        commit('setVehicleLoading', false);
        const response = await axiosInstance.get(`/v1/vehicles/${vehicleId}`);
        const vehicle = response?.data
        sendAnalytics('Partner Viewed A Vehicle Detail', {
          brand: vehicle?.brand,
          type: vehicle?.type,
          name: vehicle?.name,
          year: vehicle?.year,
          seat_count: vehicle?.seats,
          status: vehicle?.status,
          has_driver: !!vehicle?.driver?.id,
          is_active: vehicle?.status === 'active',
          amenities_list: vehicle?.amenity_list?.length ? vehicle?.amenity_list.join(', ') : '',
          inventory_type: vehicle?.inventory_type,
          created_at: vehicle?.created_at
        })
        commit('setVehicleData', response.data);
        return response;
      } finally {
        commit('setVehicleLoading', false);
      }
    }
  },
  getters: {
    getVehicleFormData: (state: VehicleState) => {
      return state.addVehicleForm;
    },
    getVehicleLoading: (state: VehicleState) => {
      return state.loadingVehicle;
    },
    getVehicleData: (state: VehicleState) => {
      return state.vehicleData;
    }
  },
  mutations: {
    setVehicleFormData (state: VehicleState, data: any) {
      state.addVehicleForm = data || null;
    },
    setVehicleData (state: VehicleState, data: any) {
      state.vehicleData = data || null;
    },
    setVehicleLoading (state: VehicleState, loading: boolean) {
      state.loadingVehicle = loading;
    }
  },
  state: {
    addVehicleForm: null,
    loadingVehicle: false,
    vehicleData: null
  }

}
