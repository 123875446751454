<template>
  <main>
    <router-view />
  </main>
</template>

<script>
export default {
};
</script>

<style>
</style>
